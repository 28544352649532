import { graphql } from "gatsby"
import React from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Seo from "../components/App/Seo"
import PageBanner from "../components/Common/PageBanner"
import StartProject from "../components/Common/StartProject"

const PrivacyPolicy = ({ data }) => {
  const { metaTags, privacyPolicy, strapiStartYourProject } = data
  const {
    info,
    infoWeCollect,
    howWeUseYourInfo,
    logFiles,
    childInfo,
    contactUs,
  } = privacyPolicy
  const { metaImage } = metaTags

  const pageMeta = metaTags?.metas?.filter(item => {
    return item.page === "privacy-policy"
  })[0]
  return (
    <Layout>
      <Seo
        url={"privacy-policy"}
        title={pageMeta?.metaTitle}
        description={pageMeta?.metaDesc}
        keywords={pageMeta?.metaKeyword}
        metaImage={metaImage}
      />
      <Navbar />
      <PageBanner
        pageTitle="Privacy Policy"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Privacy Policy"
      />
      <section className="privacy-policy-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="privacy-policy-content">
                <p>
                  <i>{info.title}</i>
                </p>
                <blockquote className="blockquote">
                  <ReactMarkdown
                    children={info.longDesc}
                    rehypePlugins={[rehypeRaw]}
                  />
                </blockquote>
                <h3>1. {infoWeCollect.title}</h3>
                <ReactMarkdown
                  children={infoWeCollect.longDesc}
                  rehypePlugins={[rehypeRaw]}
                />
                <h3>2. {howWeUseYourInfo.title}</h3>
                <ReactMarkdown
                  children={howWeUseYourInfo.longDesc}
                  rehypePlugins={[rehypeRaw]}
                />
                <h3>3. {logFiles.title}</h3>
                <ReactMarkdown
                  children={logFiles.longDesc}
                  rehypePlugins={[rehypeRaw]}
                />
                <h3>4. {childInfo.title}</h3>
                <ReactMarkdown
                  children={childInfo.longDesc}
                  rehypePlugins={[rehypeRaw]}
                />
                <h3>5. {contactUs.title}</h3>
                <ReactMarkdown
                  children={contactUs.longDesc}
                  rehypePlugins={[rehypeRaw]}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <StartProject strapiStartYourProject={strapiStartYourProject} />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetSingleHomeDetails {
    metaTags: strapiMetaTags {
      metaImage {
        url
      }
      metas {
        page
        metaTitle
        metaKeyword
        metaDesc
      }
    }
    privacyPolicy: strapiPrivacyPolicy {
      id
      info {
        id
        title
        longDesc
      }
      infoWeCollect {
        title
        longDesc
      }
      howWeUseYourInfo {
        title
        longDesc
      }
      logFiles {
        title
        longDesc
      }
      childInfo {
        title
        longDesc
      }
      contactUs {
        title
        longDesc
      }
    }
    strapiStartYourProject {
      id
      title
      shortText
      btnLink
      btnText
      images {
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
  }
`
export default PrivacyPolicy
