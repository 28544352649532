import React from "react"
import { Link } from "gatsby"
import shape9 from "../../assets/images/shape/vector-shape9.png"
import shape10 from "../../assets/images/shape/vector-shape10.png"
import ProjectStart1 from "../../assets/images/project-start1.png"

const StartProject = props => {
  const {
    title,
    shortText,
    btnLink,
    btnText,
    images: {
      localFile: {
        childImageSharp: {
          original: { src: imageSrc },
        },
      },
    },
  } = props.strapiStartYourProject

  return (
    <div className="project-start-area bg-color ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="project-start-image">
              <img src={imageSrc} alt="about" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2>{title}</h2>
              <p>{shortText}</p>

              <Link to={btnLink} className="default-btn">
                <i className="flaticon-web"></i>
                {btnText}
                <span></span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="vector-shape9">
        {/* FIXME: Background Side Image - Make it dynamic .i.e it should come from strapi */}
        <img src={shape9} alt="about" />
      </div>
      <div className="vector-shape10">
        {/* FIXME: Background Side Image - Make it dynamic .i.e it should come from strapi */}
        <img src={shape10} alt="about" />
      </div>
    </div>
  )
}

StartProject.defaultProps = {
  strapiStartYourProject: {
    title: "We Like to Start Your Project With Us",
    shortText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
    btnLink: "/contact",
    btnText: "Get Started",
    images: {
      localFile: {
        childImageSharp: {
          original: { src: ProjectStart1 },
        },
      },
    },
  }
}

export default StartProject
